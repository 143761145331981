import React, {useContext} from "react";
import style from './LoadingComponent.module.scss';
import AuthContext from "../../../../../store/auth-context";

const LoadingComponent: React.FC<{
   status: boolean;
   style?: Object
}> = props => {

   const authStore = useContext(AuthContext);

   return (
      <>
         <div style={props.style} className={style['loading-component']}>
            <main className="main-section">
               {
                  props.status ?
                     <>
                        <div className="teamo-thinking-container">
                           <img className={"teamo-image"} src="/assets/images/ai-impact-score-icons/teamo-thinking-loading-animation-icons/teamo-thinking-icon.svg" alt="Teamo thinking icon" />
                           <div className="thinking-cloud-container">
                              <img src="/assets/images/ai-impact-score-icons/teamo-thinking-loading-animation-icons/speech-bubble-icon.svg" alt="Speech bubble icon"/>
                              <img className={"sand-clock-img"} src="/assets/images/ai-impact-score-icons/teamo-thinking-loading-animation-icons/sand-clock-icon.svg" alt="Sand clock icon"/>
                           </div>
                        </div>
                        <h2 className="header-2">Just a moment, Teamo is analyzing your data.</h2>
                        <div>
                           <p>Our AI is analyzing your data.</p>
                           <p>Your personalized insights will be ready in just a moment!</p>
                        </div>
                     </> :
                     <>
                        <div className="teamo-thinking-container">
                           <img className={"teamo-image"} src="/assets/images/ai-impact-score-icons/teamo-error-loading-animation-icons/teamo-error-icon.svg" alt="Teamo error icon"/>
                           <div className="error-container">
                              <img className={'squiggle-icon'} src="/assets/images/ai-impact-score-icons/teamo-error-loading-animation-icons/squiggle-icon.svg" alt="Squiggle icon"/>
                           </div>
                        </div>
                        <h2 className="header-2">Oops! There was an error.</h2>
                        <div>
                        <p>We're sorry, we ran into an issue.</p>
                           <p>Please reach out to our support team at <a
                              href="mailto:support@teamlift.co">support@teamlift.co</a> and we'll help you out.
                           </p>
                        </div>
                        <button className="button button-primary" onClick={authStore.logout}><span>Log out</span> <i className="fa-solid fa-arrow-right-from-bracket"></i></button>
                     </>
               }
            </main>
         </div>
      </>
   );
}

export default LoadingComponent;