import React, {useContext, useEffect, useState} from "react";
import style from './AIReadinessPage.module.scss';
import {animated} from "react-spring";
import {createTrail, createTransition} from "../../../../../utils/springStyles";
import {
   ActivityDto,
   AugmentationReportTopAiToolsDto,
   QuestionnaireSessionStatusEnum,
   TopAiToolsDto
} from "../../../../../types/AugmentationData";
import {useNavigate} from "react-router-dom";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../../store/auth-context";
import useApiService from "../../../../../services/api.service";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {usePostHog} from "posthog-js/react";
import ToolCardComponent from "../AdditionalComponents/ToolCardComponent";
import ActivityComponent from "../AdditionalComponents/ActivityComponent";

const AIReadinessPage: React.FC<{
   isLoading: boolean,
   setLoadingState: (newLoadingState: QuestionnaireSessionStatusEnum) => void,
}> = props => {

   const [reportData, setReportData] = useState<AugmentationReportTopAiToolsDto>();

   const navigate = useNavigate();

   const trailActivities = createTrail<ActivityDto>(reportData?.activitiesList || []);
   const trailTools = createTrail<TopAiToolsDto>(reportData?.toolsList || []);
   const fadeInComponent = createTransition(reportData, 250);

   const posthog = usePostHog();
   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();

   useEffect(() => {
      if (!props.isLoading) {
         initializeComponent(authStore.userData.accessToken);
      }
   }, []);

   useEffect(() => {
      if (reportData?.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED) {
         posthog.capture("user_ai-readiness_page");
      }
   }, [reportData, props.isLoading, props.setLoadingState]);

   return (
      <>
         {
            !props.isLoading &&
            <div className={style['ai-readiness-page']}>
               {
                  fadeInComponent((style, data) => (
                     data && data.activePair.active ? <animated.main style={style} className="main-section-pe">
                           <h1 className="header-1">You’ve added <span className="text-primary-20">{data.totalActivities} {data.totalActivities > 1 ? "activities" : "activity"}</span>. {data.counterActivities} of them can be augmented with AI tools today.</h1>
                           <h2 className="header-2">This is your current learning opportunity, are you ready for the next one?</h2>
                           <div className="tool-activity-section">
                              <ActivityComponent activity={data.activePair.activity!} onlyTitle={true} classNames={"no-events"} />

                              <i className="fa-solid fa-plus"></i>

                              <ToolCardComponent style={{}} tool={data.activePair.tool!} onlyTitle={true} />
                           </div>

                           <div className="footer-section">
                              <button className="button button-primary" onClick={() => navigateToPage(`/augmentation/augmentation-report/pair-component/confirm?toolId=${data.activePair.tool!.tool.toolId}&activityId=${data.activePair.activity!.id}`, "from_results_to_feedback_for_tool_activity", {toolName: data.activePair.tool!.tool.name, activity: data.activePair.activity!.text})}>Did this help you?</button>
                              <div className="buttons-wrapper">
                                 <button className="button button-secondary" onClick={() => navigateToPage("/augmentation/augmentation-report/activities", "from_results_active_pair_to_activities")}>
                                    <span>See all your activities</span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                 </button>
                                 <button className="button button-secondary" onClick={() => navigateToPage("/augmentation/augmentation-report/tools", "from_results_active_pair_to_tools")}>
                                    <span>Explore more tools</span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                 </button>
                              </div>
                           </div>
                        </animated.main> : data ?
                        <animated.main style={style} className="main-section">
                           <h1 className="header-1">Teamo found <span
                              className={"text-primary-20"}>{data.totalActivities || 0} {data.totalActivities > 1 ? "activities" : "activity"}</span>. {data.counterActivities} of them can be augmented with AI tools today! Pick one to start learning.</h1>

                           <div className="activities-section">
                              <h2 className="header-2">Start from one of your activities:</h2>

                              <div className="activities-container">
                                 {
                                    trailActivities.map((style, index) => {
                                       const activity = data.activitiesList[index];
                                       const navigateURL = `/augmentation/augmentation-report/pair-component/pair?activityId=${activity.id}`;
                                       const eventName = 'chosen_suggested_activity_from_results';
                                       return (
                                          <ActivityComponent style={style} key={activity.id} activity={activity}
                                                        onClick={() => navigateToPage(navigateURL, eventName, {activityName: activity.text})}></ActivityComponent>)
                                    })
                                 }
                              </div>

                              <button className="button button-secondary"
                                      onClick={() => navigateToPage("/augmentation/augmentation-report/activities", "from_results_to_activities")}>
                                 <span>See all your activities</span>
                                 <i className="fa-solid fa-arrow-right"></i>
                              </button>

                           </div>


                           <div className="tools-section">

                              <h2 className="header-2">Start from one of the top tools we picked for you:</h2>

                              <div className="tools-container">
                                 {
                                    trailTools.map((style, index) => {
                                       const tool = data.toolsList[index];
                                       const eventNames = {
                                          p1: "chosen_suggested_tool_from_results",
                                          p2: "already_tried_suggested_tool_from_results"
                                       };
                                       return <ToolCardComponent style={style} tool={tool} eventNames={eventNames}
                                                                 key={tool.tool.toolId} />
                                    })
                                 }
                              </div>

                              <button className="button button-secondary" onClick={() => navigateToPage("/augmentation/augmentation-report/tools", "from_results_to_tools")}>
                                 <span>Explore more tools</span>
                                 <i className="fa-solid fa-arrow-right"></i>
                              </button>

                           </div>
                        </animated.main> : <></>
                  ))
               }
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   );

   function initializeComponent(accessToken: string) {
      const getAIReadinessURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/augmentation-report/ai-readiness';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .post(getAIReadinessURL, undefined, {headers})
         .then((response$: AxiosResponse<AugmentationReportTopAiToolsDto>) => {
            setReportData(response$.data);
            props.setLoadingState(response$.data.sessionStatus);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

   function navigateToPage(url: string, posthogEventName: string, posthogData?: Object) {
      posthog.capture(posthogEventName, posthogData);
      navigate(url);
   }
}

export default AIReadinessPage;