import React, {useContext, useEffect, useState} from "react";
import style from './PairPartnersComponent.module.scss';
import {createTrail, createTransition} from "../../../../../../utils/springStyles";
import { animated } from "react-spring";
import {useNavigate, useSearchParams} from "react-router-dom";
import useLoadingSpinner from "../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {
   ActivityDto, ActivityToolPairResponseDto,
   AugmentedActivitiesPerToolResponseDto,
   AugmentedToolsPerActivityResponseDto, ToolFeedbackUses, TopAiToolsDto
} from "../../../../../../types/AugmentationData";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";
import {showNotification} from "../../../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../../../store/auth-context";
import useApiService from "../../../../../../services/api.service";
import {Pagination, PaginationItem} from "@mui/material";
import {usePostHog} from "posthog-js/react";
import ToolCardComponent from "../../AdditionalComponents/ToolCardComponent";
import {scrollHandler} from "../../../../../../utils/scroll-handler";
import ActivityComponent from "../../AdditionalComponents/ActivityComponent";

const PairPartnersComponent: React.FC<{}> = props => {

   const [toolAndActivities, setToolAndActivities] = useState<AugmentedActivitiesPerToolResponseDto>();
   const [activityAndTools, setActivityAndTools] = useState<AugmentedToolsPerActivityResponseDto>();

   const [searchParams] = useSearchParams();
   const toolId = searchParams.get("toolId");
   const activityId = searchParams.get("activityId");

   const fadeInToolAndActivities = createTransition<AugmentedActivitiesPerToolResponseDto | undefined>(toolAndActivities, 250);
   const fadeInActivityAndTools = createTransition<AugmentedToolsPerActivityResponseDto | undefined>(activityAndTools, 250);
   const trailTools = createTrail<TopAiToolsDto>(activityAndTools?.tools || []);
   const trailActivities = createTrail<ActivityDto>(toolAndActivities?.activities || []);

   const navigate = useNavigate();
   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();
   const posthog = usePostHog();

   useEffect(() => {
      const page = 1;
      const pageSize = toolId ? 15 : 3;
      initializeComponent(page, pageSize, authStore.userData.accessToken);
   }, []);

   return (
      <>
         <div className={style['pair-partners-component']}>
            {
               fadeInActivityAndTools((style, data) => (
                  data && (
                     <animated.main style={style} className="partner-activity-section">
                        <h1 className="header-1">Combine this activity with an AI tool to create a learning opportunity</h1>
                        <ActivityComponent activity={data.activity} onlyTitle={true} classNames={"no-events"} />

                        <div className="tools-section">
                           {
                              data.tools.length > 0 ?
                              <>
                                 <h2 className="header-2">Pick one of the top tools for this activity:</h2>
                                 <div className="tools-container">
                                    {
                                       trailTools.map((style, index) => {
                                          const tool = data.tools[index];
                                          return <ToolCardComponent key={tool.tool.toolId} tool={tool} style={style} classNames={"actions"} onClick={toolId => savePair(toolId, data.activity.id, authStore.userData.accessToken)} />
                                       })
                                    }
                                 </div>
                                 {
                                    data.pageSize === 3 && data.totalPages > 1 ?
                                       <button className="button button-secondary" onClick={() => initializeComponent(1, 15, authStore.userData.accessToken)}>See more tools that might fit</button> :
                                       data.totalPages > 1 &&
                                       <Pagination defaultPage={1} page={data.page}
                                                   count={data.totalPages}
                                                   variant="outlined"
                                                   shape="rounded"
                                                   renderItem={(item) => <PaginationItem {...item} />}
                                                   onChange={(_e, page) => initializeComponent(page, 15, authStore.userData.accessToken)}/>
                                 }
                              </> : <>
                              <h2 className="header-2">There are no currently available tools for this activity</h2>
                              <button className="button button-primary" onClick={() => window.history.back()}>Go back</button></>
                           }
                        </div>
                     </animated.main>
                  )
               ))
            }

            {
               fadeInToolAndActivities((style, data) => (
                  data && (
                     <animated.main style={style} className="partner-tool-section">
                        <h1 className="header-1">Combine this AI tool with an activity to create a learning opportunity</h1>
                        <ToolCardComponent style={{}} tool={data.tool} />
                        <div className="activities-section">
                           {
                              data.activities.length > 0 ?
                              <>
                                 <h2 className="header-2">Pick an activity to try out this tool:</h2>
                                 <div className="activities-container">
                                    {
                                       trailActivities.map((style, index) => {
                                          const activity = data.activities[index];
                                          return <ActivityComponent key={activity.id} activity={activity} style={style} onClick={() => savePair(data.tool.tool.toolId, activity.id, authStore.userData.accessToken)} showFeedback={true} />
                                       })
                                    }
                                 </div>
                                 {
                                    data.pageSize === 15 && data.totalPages > 1 ?
                                       <button className="button button-secondary"
                                               onClick={() => initializeComponent(1, 30, authStore.userData.accessToken)}>See more activities</button> :
                                       data.totalPages > 1 &&
                                       <Pagination defaultPage={1} page={data.page}
                                                   count={data.totalPages}
                                                   variant="outlined"
                                                   shape="rounded"
                                                   renderItem={(item) => <PaginationItem {...item} />}
                                                   onChange={(_e, page) => initializeComponent(page, 30, authStore.userData.accessToken)}/>
                                 }
                              </> :
                                 <>
                                    <h2 className="header-2">There are no currently available activities for this tool</h2>
                                    <button className="button button-primary" onClick={() => window.history.back()}>Go back</button>
                                 </>
                           }
                        </div>
                     </animated.main>
                  )
               ))
            }
         </div>
         {
            spinnerService.spinner
         }
      </>
   );

   function initializeComponent(page: number, pageSize: number, accessToken: string) {
      const url = toolId ? "activities-for-tool" : "tools-for-activity";
      const getToolsOrActivitiesURL = process.env.REACT_APP_PUBLIC_URL + `/augmentation/augmentation-report/${url}`;

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         page,
         pageSize,
         ...(toolId ? {toolId: Number(toolId)} : {activityId: Number(activityId)}),
      };

      spinnerService.createSpinner();

      axios
         .post(getToolsOrActivitiesURL, data, {headers})
         .then((response$: AxiosResponse<AugmentedToolsPerActivityResponseDto | AugmentedActivitiesPerToolResponseDto>) => {
            if (response$.data.activityToolPairResponse.active) {
               navigate(`/augmentation/augmentation-report/pair-component/confirm?toolId=${response$.data.activityToolPairResponse.tool!.tool.toolId}&activityId=${response$.data.activityToolPairResponse.activity!.id}`);
               return;
            }
            scrollHandler(0, 'auto');
            posthog.capture("user_tries_pair_component", {cameFrom: toolId ? "From tool" : "From activity"});
            toolId ? setToolAndActivities(response$.data as AugmentedActivitiesPerToolResponseDto) : setActivityAndTools(response$.data as AugmentedToolsPerActivityResponseDto);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(page, pageSize, response$.data.accessToken);
                  })
            } else {
               navigate("/augmentation/augmentation-report");
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

   function savePair(toolId: number, activityId: number, accessToken: string) {
      const savePairURL = process.env.REACT_APP_PUBLIC_URL + `/augmentation/augmentation-report/activity-tool-pair`;

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         toolId,
         activityId,
         feedback: ToolFeedbackUses.START_USING
      };

      spinnerService.createSpinner();

      axios
         .post(savePairURL, data, {headers})
         .then((_response$: AxiosResponse<ActivityToolPairResponseDto>) => {
            posthog.capture("user_chose_pair", {toolId, activityId, chosePair: true});
            navigate(`/augmentation/augmentation-report/pair-component/confirm?toolId=${toolId}&activityId=${activityId}`);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     savePair(toolId, activityId, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }
}

export default PairPartnersComponent;