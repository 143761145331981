import React, {useContext, useEffect, useState} from "react";
import style from './EmployeeOnboardPage.module.scss';
import IntroductionPage from "./IntroductionPage/IntroductionPage";
import NamePage from "./NamePage/NamePage";
import RolePage from "./RolePage/RolePage";
import UploadPage from "./UploadPage/UploadPage";
import ProfileBoostPage from "./ProfileBoostPage/ProfileBoostPage";
import MapTeamSkillsPage from "./MapTeamSkillsPage/MapTeamSkillsPage";
import AuthContext from "../../../../store/auth-context";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import useApiService from "../../../../services/api.service";
import {useNavigate} from "react-router-dom";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";

export enum EmployeeOnboardPages {
   INTRODUCTION_PAGE = 0,
   NAME_PAGE = 1,
   ROLE_PAGE = 2,
   GITHUB_PAGE = 3,
   LINKEDIN_PAGE = 4,
   CV_PAGE= 5,
   PROFILE_BOOST_PAGE = 6,
   MAP_TEAM_SKILLS = 7
}

const EmployeeOnboardPage: React.FC<{}> = props => {

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const navigate = useNavigate();
   const [employeeId, setEmployeeId] = useState<number>();
   const [employeeSection, setEmployeeSection] = React.useState<EmployeeOnboardPages>(EmployeeOnboardPages.INTRODUCTION_PAGE);
   const spinnerService = useLoadingSpinner();

   useEffect(() => {
      getEmployeeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <>
         <div className={style['employee-onboard-page']}>
            <div className="employee-onboard-header">
               <img src="/assets/images/global-icons/teamlift-logo-white.svg" alt="TeamLift logo"/>
               <button className="button button-tertiary" onClick={authStore.logout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Log out</button>
            </div>
            <div className="onboard-container">
               {
                  employeeSection !== EmployeeOnboardPages.INTRODUCTION_PAGE &&
                  <div className="progress-bar-onboard">
                     {
                        Object.values(EmployeeOnboardPages).filter(value => typeof value === "number" && value > 0 && (!(!authStore.userData.isManager && value === 7)))
                           .map(employeePage => <div className={Number(employeePage) <= Number(employeeSection) ? "active" : ""} key={employeePage}></div>)
                     }
                  </div>
               }
               <main>
                  {
                     employeeSection === EmployeeOnboardPages.INTRODUCTION_PAGE &&
                     <IntroductionPage changeSection={(isBack?: boolean) => changeSection(authStore.userData.accessToken, isBack)}/>
                  }
                  {
                     employeeSection === EmployeeOnboardPages.NAME_PAGE &&
                     <NamePage changeSection={(isBack?: boolean) => changeSection(authStore.userData.accessToken, isBack)}/>
                  }
                  {
                     employeeSection === EmployeeOnboardPages.ROLE_PAGE &&
                     <RolePage changeSection={(isBack?: boolean) => changeSection(authStore.userData.accessToken, isBack)}/>
                  }
                  {
                     employeeSection === EmployeeOnboardPages.GITHUB_PAGE &&
                     <UploadPage uploadType={EmployeeOnboardPages.GITHUB_PAGE} changeSection={(isBack?: boolean) => changeSection(authStore.userData.accessToken, isBack)}/>
                  }
                  {
                     employeeSection === EmployeeOnboardPages.LINKEDIN_PAGE &&
                     <UploadPage uploadType={EmployeeOnboardPages.LINKEDIN_PAGE} changeSection={(isBack?: boolean) => changeSection(authStore.userData.accessToken, isBack)}/>
                  }
                  {
                     employeeSection === EmployeeOnboardPages.CV_PAGE &&
                     <UploadPage uploadType={EmployeeOnboardPages.CV_PAGE} changeSection={(isBack?: boolean) => changeSection(authStore.userData.accessToken, isBack)}/>
                  }
                  {
                     employeeSection === EmployeeOnboardPages.PROFILE_BOOST_PAGE &&
                     <ProfileBoostPage changeSection={(isBack?: boolean) => changeSection(authStore.userData.accessToken, isBack)} />
                  }
                  {
                     employeeSection === EmployeeOnboardPages.MAP_TEAM_SKILLS && authStore.userData.isManager &&
                     <MapTeamSkillsPage changeSection={(isBack?: boolean) => changeSection(authStore.userData.accessToken, isBack)} />
                  }
               </main>
            </div>
         </div>
         {
            spinnerService.spinner
         }
      </>
   )

   function finishOnboarding(accessToken: string) {
      const finishOnboardingURL = process.env.REACT_APP_PUBLIC_URL + '/employee/finish-onboarding';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .post(finishOnboardingURL, undefined, {headers})
         .then(() => {
            spinnerService.removeSpinner();
            authStore.changeOnboarding();
            if (authStore.userData.isManager) {
               navigate("/manager/projects");
            }
            else {
               navigate("/employee/skills-wallet");
            }
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     finishOnboarding(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getEmployeeComponent(accessToken: string) {
      const getEmployeeComponentURL = process.env.REACT_APP_PUBLIC_URL + '/employee/fe-component';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .get(getEmployeeComponentURL, {headers})
         .then((response$: AxiosResponse<{id: number, feComponent: string, isOnboarding: boolean}>) => {
            spinnerService.removeSpinner();
            if (response$.data.isOnboarding) {
               setEmployeeId(+response$.data.id);
               if (response$.data.feComponent) {
                  setEmployeeSection(+response$.data.feComponent);
               }
            }
            else {
               if (authStore.userData.isOnboarding) {
                  authStore.changeOnboarding();
               }
               if (authStore.userData.isManager) {
                  navigate("/manager/projects");
               }
               else {
                  navigate("/employee/skills-wallet");
               }
            }
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getEmployeeComponent(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })

   }

   function changeSection(accessToken: string, isBack?: boolean) {
      if (!authStore.userData.isManager && employeeSection === EmployeeOnboardPages.PROFILE_BOOST_PAGE && !isBack) {
         finishOnboarding(accessToken);
         return;
      }
      if (authStore.userData.isManager && employeeSection === EmployeeOnboardPages.MAP_TEAM_SKILLS && !isBack) {
         finishOnboarding(accessToken);
         return;
      }

      const updateEmployeeComponent = process.env.REACT_APP_PUBLIC_URL + '/employee/update-employee-fe-component';
      const nextSection = isBack ? (employeeSection - 1) : (employeeSection + 1);

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         employeeId: employeeId,
         component: nextSection.toString(),
      }

      spinnerService.createSpinner();

      axios
         .post(updateEmployeeComponent, data, {headers})
         .then(() => {
            spinnerService.removeSpinner();
            setEmployeeSection(nextSection as EmployeeOnboardPages);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     changeSection(response$.data.accessToken, isBack);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }
}

export default EmployeeOnboardPage;