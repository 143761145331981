import React, {useContext, useEffect, useState} from "react";
import style from './ToolsPage.module.scss';
import {animated} from "react-spring";
import {createTrail, createTransition} from "../../../../../utils/springStyles";
import {
   QuestionnaireSessionStatusEnum,
   ToolsPaginationResponseDto,
   TopAiToolsDto
} from "../../../../../types/AugmentationData";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../../../../store/auth-context";
import useApiService from "../../../../../services/api.service";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import {Pagination, PaginationItem} from "@mui/material";
import {usePostHog} from "posthog-js/react";
import ToolCardComponent from "../AdditionalComponents/ToolCardComponent";
import {scrollHandler} from "../../../../../utils/scroll-handler";

const ToolsPage: React.FC<{
   isLoading: boolean,
   setLoadingState: (newLoadingState: QuestionnaireSessionStatusEnum) => void,
}> = props => {

   const [reportTools, setReportTools] = useState<ToolsPaginationResponseDto>();

   const trailTools = createTrail<TopAiToolsDto>(reportTools?.tools || []);
   const fadeInComponent = createTransition<ToolsPaginationResponseDto | undefined>(reportTools, 250);
   const navigate = useNavigate();
   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();
   const posthog = usePostHog();

   useEffect(() => {
      if (!props.isLoading) {
         initializeComponent(1, 15, authStore.userData.accessToken);
      }
   }, []);

   useEffect(() => {
      if (reportTools?.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED) {
         posthog.capture("user_tools_page");
      }
   }, [reportTools, props.isLoading, props.setLoadingState]);

   return (
      <>
         {
            !props.isLoading &&
            <div className={style['tools-page']}>
               {
                  fadeInComponent((style, data) => (
                     data && (
                        <animated.main style={style} className={`main-container ${data.tools.length === 0 ? "no-tools" : ""}`}>
                           {
                              data.tools.length > 0 ?
                                 <>
                                    <h1 className="header-1">Based on your <span
                                       className="text-primary-20">activities</span>,
                                       Teamo thinks these are the best tools for you right now.</h1>

                                    <div className="tools-container">
                                       {
                                          trailTools.map((style, index) => {
                                             const tool = data.tools[index];
                                             const eventNames = {
                                                p1: "chosen_tool_from_tools",
                                                p2: "already_used_tool_from_tools"
                                             };
                                             return <ToolCardComponent key={tool.tool.toolId} style={style} tool={tool} eventNames={eventNames} />
                                          })
                                       }
                                    </div>
                                 </> :
                                 <>
                                    <h1 className="header-1">Based on your <span className="text-primary-20">activities</span>, Teamo doesn’t have any tool suggestions for you right now.</h1>
                                    <div className="image-wrapper">
                                       <img src="/assets/images/ai-impact-score-icons/teamo-sad-icon.svg"
                                            alt="Teamo sad icon"/>
                                       <i className="fa-solid fa-circle-question"></i>
                                    </div>
                                    <p>You can always add more activities, and from them, we can help you discover the
                                       best AI tools tailored to your needs.</p>
                                    <button className="button button-primary" onClick={() => navigate("/augmentation/augmentation-report/activities")}>Add more activities</button>
                                 </>
                           }

                           {
                              data.totalPages > 1 &&
                              <div className="pagination-row">
                                 <div className="categories">
                                    <div className="categories-title">Tools per page:</div>
                                    <div className="categories-number">{data.pageSize}</div>
                                 </div>
                                 <Pagination defaultPage={1} page={data.page}
                                             count={data.totalPages}
                                             variant="outlined"
                                             shape="rounded"
                                             renderItem={(item) => <PaginationItem {...item} />}
                                             onChange={(_e, page) => initializeComponent(page, 15, authStore.userData.accessToken)}/>
                              </div>
                           }

                        </animated.main>
                     )
                  ))
               }
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   );

   function initializeComponent(page: number, pageSize: number, accessToken: string) {
      const getToolsURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/augmentation-report/tools';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         page,
         pageSize
      }

      spinnerService.createSpinner();

      axios
         .post(getToolsURL, data, {headers})
         .then((response$: AxiosResponse<ToolsPaginationResponseDto>) => {
            setReportTools(response$.data);
            props.setLoadingState(response$.data.sessionStatus);
            spinnerService.removeSpinner();
            scrollHandler(0, 'auto');
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(page, pageSize, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }
}

export default ToolsPage;