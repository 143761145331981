import {EmployeeDto, SkillDto} from "./Settings";
import {ActivityDto, QuestionnaireSessionStatusEnum} from "./AugmentationData";

export type AugmentationQuestionDto = {
   id: number;
   aiId: string;
   questionText: string;
   preferredAnswer: string;
   questionOrder: number;
   isAlreadyAnswered: boolean;
   answerType: string;
   sessionStatus: QuestionnaireSessionStatusEnum;
   employee: EmployeeDto;
   skills?: SkillDto[];
   activities?: ActivityDto[];
}

export enum QuestionTypeEnum {
   TEXT = "TEXT",
   CHECKBOX = "CHECKBOX",
   SKILL_SELECTION = "SKILL_SELECTION",
   SHORT_TEXT = "SHORT_TEXT",
   ACTIVITY_SELECTION= "ACTIVITY_SELECTION"
}