import React, {useContext, useEffect, useRef, useState} from "react";
import style from './ActivitiesPage.module.scss';
import {animated} from "react-spring";
import {createTrail, createTransition, createTransitionWithExit} from "../../../../../utils/springStyles";
import {
   ActivitiesPaginationResponseDto,
   ActivityDto,
   QuestionnaireSessionStatusEnum
} from "../../../../../types/AugmentationData";
import {useNavigate} from "react-router-dom";
import {Pagination, PaginationItem} from "@mui/material";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import useApiService from "../../../../../services/api.service";
import AuthContext from "../../../../../store/auth-context";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {usePostHog} from "posthog-js/react";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import ActivityComponent from "../AdditionalComponents/ActivityComponent";

const ActivitiesPage: React.FC<{
   isLoading: boolean,
   setLoadingState: (newLoadingState: QuestionnaireSessionStatusEnum) => void,
}> = props => {

   const [reportActivities, setReportActivities] = useState<ActivitiesPaginationResponseDto>();
   const [activityInput, setActivityInput] = useState('');
   const [addedActivities, setAddedActivities] = useState<string[]>([]);
   const [deletedActivities, setDeletedActivities] = useState<number[]>([]);
   const activityInputRef = useRef<HTMLInputElement>(null);

   const trailActivities = createTrail<ActivityDto>(reportActivities?.activities || []);
   const fadeInComponent = createTransition<ActivitiesPaginationResponseDto | undefined>(reportActivities, 250);
   const trailAddedActivities = createTrail<string>(addedActivities);
   const fadeInActivityContainer = createTransitionWithExit(addedActivities.length > 0, 250);
   const fadeInButton = createTransitionWithExit(addedActivities.length > 0 || deletedActivities.length > 0, 250);

   const posthog = usePostHog();
   const navigate = useNavigate();
   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();
   const delimiter = "\n";

   useEffect(() => {
      if (!props.isLoading) {
         initializeComponent(1, 30, authStore.userData.accessToken);
      }
   }, []);

   useEffect(() => {
      if (reportActivities?.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED) {
         posthog.capture("user_activities_page");
      }
   }, [reportActivities, props.isLoading, props.setLoadingState]);

   return (
      <>
         {
            !props.isLoading &&
            <div className={style['activities-page']}>
               {
                  fadeInComponent((style, data) => (
                     data && (
                        <animated.main style={style} className="main-section">

                           <h1 className="header-1">You've added <span className={"text-primary-20"}>{data.totalActivities} {data.totalActivities > 1 ? "activities" : "activity"}</span>. {data.counterActivities} of them can be augmented with AI tools today.</h1>

                           <div className="activity-section">
                              <div className="add-activity-section">

                                 <div className="activity-input-container">
                                    <input type="text" className={'custom-input'}
                                           ref={activityInputRef}
                                           placeholder={"Add another activity: Creating a cold email campaign"}
                                           onKeyDown={activityInputHandler} value={activityInput}
                                           onChange={e => setActivityInput(e.target.value)}/>
                                    <button className="button button-primary"
                                            onClick={() => activityInputHandler(undefined, true)}
                                            disabled={activityInput.trim().length < 2}>Add activity</button>
                                 </div>
                              </div>

                              <div className={`activities-containers ${addedActivities.length > 0 ? "added-gap" : ""}`}>
                                 <div className="activities-container">
                                    {
                                       fadeInActivityContainer((style, showContainer) => (
                                          showContainer &&
                                          <animated.div style={style} className="activities-container inner">
                                             {
                                                trailAddedActivities.map((style, index) => {
                                                   const activityText = addedActivities[index];
                                                   return <ActivityComponent key={index + activityText.slice(0, 2)} style={style} isNew={activityText} canDelete={true} deleteActivity={() => deleteAddedActivity(index)} />
                                                })
                                             }
                                          </animated.div>
                                       ))
                                    }
                                 </div>
                                 <div className={`activities-container`}>
                                    {
                                       trailActivities.map((style, index) => {
                                          const activity = data.activities[index];
                                          const isDeleted = findDeletedActivity(activity.id);
                                          const navigateURL = `/augmentation/augmentation-report/pair-component/pair?activityId=${activity.id}`;
                                          const eventName = 'chosen_activity_from_activities';
                                          return <ActivityComponent key={activity.id} activity={activity} isDeleted={isDeleted} classNames={isDeleted}
                                                                    onClick={() => !isDeleted && navigateToPage(navigateURL, eventName, {activityName: activity.text})}
                                                                    style={style} canDelete={true} deleteActivity={() => deleteActivity(activity.id)} />
                                       })
                                    }
                                 </div>
                              </div>
                           </div>

                           {
                              data.totalPages > 1 &&
                              <div className="pagination-row">
                                 <div className="categories">
                                    <div className="categories-title">Activities per page:</div>
                                    <div className="categories-number">{data.pageSize}</div>
                                 </div>
                                 <Pagination defaultPage={1} page={data.page}
                                             count={data.totalPages}
                                             variant="outlined"
                                             shape="rounded"
                                             renderItem={(item) => <PaginationItem {...item} />}
                                             onChange={(_e, page) => initializeComponent(page, 30, authStore.userData.accessToken)}/>
                              </div>
                           }

                           {
                              fadeInButton((style, showButton) => (
                                 showButton && <animated.div style={style} className="button-section">
                                    <button className="button button-primary"
                                            onClick={() => updateActivities(authStore.userData.accessToken)}>Save
                                       changes
                                    </button>
                                 </animated.div>
                              ))
                           }

                        </animated.main>
                     )
                  ))
               }
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   );

   function initializeComponent(page: number, pageSize: number, accessToken: string) {
      const getActivitiesURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/augmentation-report/activities';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         page,
         pageSize
      }

      spinnerService.createSpinner();

      axios
         .post(getActivitiesURL, data, {headers})
         .then((response$: AxiosResponse<ActivitiesPaginationResponseDto>) => {
            setReportActivities(response$.data);
            props.setLoadingState(response$.data.sessionStatus);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(page, pageSize, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

   function updateActivities(accessToken: string) {
      if (deletedActivities.length === reportActivities?.activities.length && addedActivities.length === 0) {
         showNotification("warning", "Deleting all existing activities is not allowed. Please modify your selection to proceed.", "Action Forbidden", `${deletedActivities.length}_${addedActivities.length}`);
         return;
      }
      const saveActivitiesURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/augmentation-report/update-activities';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      let newActivities = "";
      addedActivities.forEach((activity, index) => {
         index === 0 ? newActivities += activity : newActivities+= delimiter + activity;
      });

      const data = {
         newActivities,
         deletedActivities
      }

      spinnerService.createSpinner();

      axios
         .post(saveActivitiesURL, data, {headers})
         .then((response$: AxiosResponse<boolean>) => {
            posthog.capture("user_changed_activities", {deleted: deletedActivities.length, added: addedActivities.length});
            if (response$.data) {
               props.setLoadingState(QuestionnaireSessionStatusEnum.UPDATED_REPORT_PROCESSING);
            }
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     updateActivities(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

   function navigateToPage(url: string, posthogEventName: string, posthogData?: Object) {
      posthog.capture(posthogEventName, posthogData);
      navigate(url);
   }

   function activityInputHandler(e?: React.KeyboardEvent<HTMLInputElement>, fromButton?: boolean) {
      if (activityInput.trim().length > 1 && ((e && e.key === 'Enter') || fromButton)) {
         const fromAdded = addedActivities.find(a => a.toLowerCase().trim() === activityInput.toLowerCase().trim());
         const fromExisting = reportActivities && reportActivities.activities.find(a => a.text.toLowerCase().trim() === activityInput.toLowerCase().trim());
         if (fromAdded || fromExisting) {
            showNotification("warning", "This activity already exists. Please consider adding a different one.", "Action forbidden!", activityInput.toLowerCase().trim());
         }
         else {
            setAddedActivities(prevState => [...prevState, capitalizeFirstLetter(activityInput.trim())]);
            setActivityInput('');
            fromButton && activityInputRef.current?.focus();
         }
      }
   }

   function deleteAddedActivity(index: number) {
      setAddedActivities(prevState => prevState.filter((_a, i) => index !== i));
   }

   function findDeletedActivity(id: number) {
      return deletedActivities.find(daId => daId === id) ? "deleted with-buttons" : "";
   }

   function deleteActivity(id: number) {
      if (findDeletedActivity(id) === "") {
         setDeletedActivities(prevState => [...prevState, id]);
      }
      else {
         setDeletedActivities(prevState => prevState.filter(aId => aId !== id));
      }
   }
}

export default ActivitiesPage;