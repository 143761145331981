import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import AuthContext from "../../../../store/auth-context";
import {QuestionnaireSessionStatusEnum,} from "../../../../types/AugmentationData";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import style from './AIImpactScoreRoutes.module.scss';
import ToolsPage from "./ToolsPage/ToolsPage";
import ActivitiesPage from "./ActivitiesPage/ActivitiesPage";
import AIReadinessPage from "./AIReadinessPage/AIReadinessPage";
import LearningPairComponent from "./LearningPairComponent/LearningPairComponent";
import LoadingComponent from "./LoadingComponent/LoadingComponent";
import LearningOpportunityPage from "./LearningOpportunityPage/LearningOpportunityPage";

const AIImpactScoreRoutes: React.FC<{
   showMenu: (flag: boolean) => void
}> = props => {


   const [loadingState, setLoadingState] = useState<QuestionnaireSessionStatusEnum>();
   const isLoading = !!(loadingState && loadingState !== QuestionnaireSessionStatusEnum.FINISHED);

   const authStore = useContext(AuthContext);
   const spinnerService = useLoadingSpinner();

   useEffect(() => {
      authStore.channelRegister.unbind(`augmentation_${authStore.userData.userId}`);
      authStore.channelRegister.bind(`augmentation_${authStore.userData.userId}`, (_data: { message: string }) => {
         setLoadingState(QuestionnaireSessionStatusEnum.FINISHED); // set loading state to finished so it will trigger re-render
      });
   }, []);

   useLayoutEffect(() => {
      const isLoading = !!(loadingState && loadingState !== QuestionnaireSessionStatusEnum.FINISHED);
      props.showMenu(!isLoading);
   }, [loadingState, props.showMenu]);

   return (
      <div className={style['ai-impact-score-routes']}>
         <main className="ai-impact-main">
            {
               isLoading && <LoadingComponent status={loadingState === QuestionnaireSessionStatusEnum.PROCESSING || loadingState === QuestionnaireSessionStatusEnum.UPDATED_REPORT_PROCESSING} />
            }
            <Routes>
               {
                  <Route path='*' element={<Navigate to='/augmentation/augmentation-report/ai-readiness'></Navigate>} />
               }
               {
                  <Route path={"/ai-readiness"} element={<AIReadinessPage key={String(isLoading)} isLoading={isLoading} setLoadingState={setLoadingStateHandler} />} />
               }
               {
                  <Route path={"/activities"} element={<ActivitiesPage key={String(isLoading)} isLoading={isLoading} setLoadingState={setLoadingStateHandler} />} />
               }
               {
                  <Route path={"/tools"} element={<ToolsPage key={String(isLoading)} isLoading={isLoading} setLoadingState={setLoadingStateHandler} />} />
               }
               {
                  !isLoading &&
                  <Route path={"/pair-component/:section"} element={<LearningPairComponent />} />
               }
            </Routes>
         </main>
         {
            spinnerService.spinner
         }
      </div>
   );

   function setLoadingStateHandler(newLoadingState: QuestionnaireSessionStatusEnum) {
      setLoadingState(newLoadingState);
   }
}

export default AIImpactScoreRoutes;