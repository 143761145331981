import React, {useContext, useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {ToastContainer} from 'react-toastify';

import styles from './MainPage.module.scss';

import AuthContext from '../../store/auth-context';
import {CreateProjectContextProvider} from '../../store/create-project-context';
import {AdminPanelContextProvider} from '../../store/admin-panel-context';

import MenuComponent from './MenuComponent/MenuComponent';
import HeaderComponent from './HeaderComponent/HeaderComponent';
import PageNotFound from './PageNotFound/PageNotFound';

import SkillsWalletPage from './EmployeePages/SkillsWalletPage/SkillsWalletPage';
import EmployeeDashboard from './EmployeePages/EmployeeDashboard/EmployeeDashboard';
import SkillsGrowthPage from './EmployeePages/SkillsGrowthPage/SkillsGrowthPage';
import JiraIntegration from './UploadDataPage/UploadCompanyDataComponent/JiraIntegration/JiraIntegration';
import ProjectsPage from './ManagerPages/ProjectsPage/ProjectsPage';
import AdminPanelPage from './AdminPages/AdminPanelPage/AdminPanelPage';

import OnboardingEmployeePage from './AdminPages/AdminOnboardingPage/AdminOnboardingPage';
import OnboardingManagerPage from './ManagerPages/OnboardingManagerComponent/OnboardingManagerComponent';

import UnsubscribeJira from './UploadDataPage/UploadCompanyDataComponent/JiraIntegration/UnsubscribeJira';
import SkillsInventoryPage from "./ManagerPages/SkillsInventoryPage/SkillsInventoryPage";
import {ProjectsContextProvider} from "../../store/projects-context";
import EmployeeSettingsPage from "./EmployeePages/EmployeeSettingsPage/EmployeeSettingsPage";
import useLoadingSpinner from "../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {SKILL_INVENTORY_TYPE} from "../../types/SkillsInventoryData";
import ChatBotPage from "./ManagerPages/ChatBotPage/ChatBotPage";
import JobPostingsPage from "./ManagerPages/JobPostingsPage/JobPostingsPage";
import {MenusEndpointsDto} from "../../types/UserMenu";
import EmployeeOnboardPage from "./EmployeePages/EmployeeOnboardPage/EmployeeOnboardPage";
import PersonaPage from "./EmployeePages/PersonaPage/PersonaPage";
import UploadDataPage from "./UploadDataPage/UploadDataPage";
import {UploadDataType} from "../../types/UploadData";
import PersonalityInsights from "./EmployeePages/PersonalityInsights/PersonalityInsights";
import SuperAdminPage from "./SuperAdminPage/SuperAdminPage";
import {UserRoleDto} from "../../types/AuthData";
import EmployeeOnboardRegisterPage from "./EmployeePages/EmployeeOnboardRegisterPage/EmployeeOnboardRegisterPage";
import AIImpactScoreRoutes from "./EmployeePages/AIImpactScorePage/AIImpactScoreRoutes";
import AnnotatorPage from "./AnnotatorPage/AnnotatorPage";

const MainPage: React.FC = () => {
	const authStore = useContext(AuthContext);
	const userData = authStore.userData;
	const location = useLocation();
	const navigate = useNavigate();

	const employeeMenu = userData.menu[0].menus?.find(item => item.title.includes("Employee"));
	const managerMenu = userData.menu[0].menus?.find(item => item.title.includes("Manager"));
	const adminPanel = userData.menu[0].menus?.find(item => item.title.includes("Admin"));
	const superAdminPanel = userData.menu[0].menus?.find(item => item.title.includes("Super Admin"));
	const annotatorPanel = userData.menu[0].menus?.find(item => item.title.includes("Annotator"));
	const augmentationReport = userData.menu[0].menus?.find(item => item.title.includes("Freemium Individual"));
	let homePage: string;
	const onboardingFlag = location.pathname.startsWith("/admin-onboard/admin-onboarding") ||  location.pathname.startsWith("/employee/employee-onboard") || location.pathname.startsWith("/persona");

	const spinnerService = useLoadingSpinner();

	const [smallMenu, setSmallMenu] = useState(false);
	const [showMenu, setShowMenu] = useState(true);

	if (userData.isOnboarding) {
		if (userData.userRole === UserRoleDto.FREEMIUM_INDIVIDUAL.toString() && augmentationReport) {
			homePage = "/employee/employee-onboard-register";
		}
		else {
			homePage = '/employee/employee-onboard';
		}
	}
	else if (superAdminPanel) {
		homePage = '/super-admin/super-admin-panel';
	}
	else if (adminPanel) {
		homePage = '/admin-onboard/admin-panel';
	}
	else {
		homePage = findHomePage(userData.menu);
	}

	useEffect(() => {
		if (userData.isOnboarding) {
			if (userData.userRole === UserRoleDto.FREEMIUM_INDIVIDUAL.toString() && augmentationReport) {
				navigate("/employee/employee-onboard-register");
			}
			else {
				navigate("/employee/employee-onboard");
			}
		}
	}, [userData, navigate]);

	return (
		<div className={styles['main-page']}>
			{
				!onboardingFlag && showMenu && <MenuComponent smallMenu={smallMenu} changeMenu={() => setSmallMenu(prevState => !prevState)} />
			}
			<div className={`main-wrapper`}>
				{
					!onboardingFlag && showMenu && <HeaderComponent accessToken={authStore.userData.accessToken} changeMenu={() => setSmallMenu(prevState => !prevState)} />
				}
				<div id={"main-content"} className={`main-content ${onboardingFlag ? "onboarding" : ""}`}>
					<Routes>
						<Route path="/" element={<Navigate replace to={homePage}></Navigate>} />

						{/* Employee menu */}
						{	employeeMenu &&
							<Route path="/employee/skills-wallet" element={<SkillsWalletPage />} />
						}
						{
							employeeMenu &&
							<Route path={"/answer/calculate-results"} element={<PersonalityInsights />} />
						}
						{
							employeeMenu &&
							<Route path="/employee/employee-onboard" element={<EmployeeOnboardPage />} />
						}
						{ employeeMenu &&
							<Route path="/employee/employee-dashboard" element={<EmployeeDashboard />} />
						}
						{ employeeMenu &&
							<Route path="/file/personal-data-types" element={<UploadDataPage type={UploadDataType.PERSONAL}/>}/>
						}
						{ employeeMenu &&
							<Route path="/employee/skill-growth" element={<SkillsGrowthPage />} />
						}
						{
							employeeMenu &&
							<Route path={"/employee/ai-impact-score"} element={<AIImpactScoreRoutes showMenu={flag => setShowMenu(flag)} />} />
						}
						{
							employeeMenu &&
							<Route path="/persona/*" element={<PersonaPage />} />
						}
						{
							employeeMenu &&
							<Route path="/auth/personal-info" element={
								<EmployeeSettingsPage /> } />
						}

						{ managerMenu &&
							<Route path="/manager/projects/*" element={<ProjectsPage />} />
						}
						{
							managerMenu &&
							<Route path="/manager/tasks/*" element={<JobPostingsPage />} />
						}

						{ managerMenu &&
							<Route path="/manager/projects-skill-set" element={
								<ProjectsContextProvider>
								<SkillsInventoryPage key={'project'} type={SKILL_INVENTORY_TYPE.PROJECT} />
							</ProjectsContextProvider>} />
						}

						{ (managerMenu || adminPanel) &&
							<Route path="/manager/company-skill-set" element={
								<ProjectsContextProvider>
									<SkillsInventoryPage key={'company'} type={SKILL_INVENTORY_TYPE.COMPANY} />
								</ProjectsContextProvider>
							} />
						}

						{ managerMenu &&
							<Route path="/manager/onboarding-manager" element={
								<CreateProjectContextProvider>
									<OnboardingManagerPage />
								</CreateProjectContextProvider>
							} />
						}
						{ (managerMenu || adminPanel) &&
							<Route path="/file/company-data-types" element={<UploadDataPage type={UploadDataType.COMPANY} />} />
						}
						{ managerMenu &&
							<Route path="/auth/integrate-jira" element={<JiraIntegration />} />
						}
						{ managerMenu &&
							<Route path="/auth/unsubscribe-jira" element={<UnsubscribeJira />}/>
						}
						{ (managerMenu || adminPanel) &&
							<Route path="/manager/chat-bot" element={<ChatBotPage />} />
						}
						
						{/* Admin onboarding */}
						{ adminPanel &&
							<Route path="/admin-onboard/admin-panel" element={
								<AdminPanelContextProvider>
									<AdminPanelPage />
								</AdminPanelContextProvider>
							}/>
						}
						{ adminPanel &&
							<Route path="/admin-onboard/admin-onboarding" element={
								<AdminPanelContextProvider>
									<OnboardingEmployeePage />
								</AdminPanelContextProvider>
							}/>
						}
						{
							superAdminPanel &&
							<Route path={"/super-admin/super-admin-panel"} element={
								<SuperAdminPage />
							} />
						}
						{
							annotatorPanel &&
							<Route path={"/annotator/annotator-panel"} element={
								<AnnotatorPage />
							} />
						}
						{
							augmentationReport && !authStore.userData.isOnboarding &&
							<Route path={"/augmentation/augmentation-report/*"} element={<AIImpactScoreRoutes showMenu={flag => setShowMenu(flag)} />} />
						}
						{
							augmentationReport && authStore.userData.isOnboarding &&
							<Route path={"/employee/employee-onboard-register"} element={<EmployeeOnboardRegisterPage />} />
						}

						<Route path='*' element={<PageNotFound />} />
					</Routes>
				</div>
			</div>			

			<ToastContainer 
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
			/>
			{
				spinnerService.spinner
			}
		</div>
	);

	function findHomePage(userMenu: MenusEndpointsDto[]): string {
		let urlHomePage: string;
		let upperLeftMenu = userMenu.find(item => item.title === 'Upper Left')!;
		let homepage = undefined;

		// User has one role
		if (upperLeftMenu.menus?.length === 1) {
			homepage = findHomePageItem(upperLeftMenu.menus[0]);
			
			if (homepage && homepage.url) {
				urlHomePage = homepage.url;
			}
			else {
				urlHomePage = upperLeftMenu.menus![0].menus![0].url!;
			}
		}
		else { // User has both roles (employee and manager (and or) admin)
			let managerHomePage: string = '';
			let employeeHomePage: string = '';

			for (let menu of upperLeftMenu.menus!) {
				homepage = findHomePageItem(menu);
				if (menu.title.includes("Manager") || menu.title.includes("Admin")) {
					if (homepage && homepage.url) {
						managerHomePage = homepage.url;
					}
				}
				else {
					if (homepage && homepage.url) {
						employeeHomePage = homepage.url;
					}	
				}
			}
			urlHomePage = managerHomePage !== '' ? managerHomePage : employeeHomePage;
		}
		return urlHomePage;
	}

	function findHomePageItem(menuItem: MenusEndpointsDto): MenusEndpointsDto | null {
		if (menuItem.url && menuItem.homepage) {
			return menuItem;
		} else if (menuItem.menus) {
			for (const m of menuItem.menus) {
				const foundMenuItem = findHomePageItem(m);
				if (foundMenuItem) {
					return foundMenuItem;
				}
			}
		}
		return null;
	}
};

export default MainPage;