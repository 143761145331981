import React, {MouseEvent} from "react";
import { animated } from "react-spring";
import {ActivityDto, ToolFeedbackUses} from "../../../../../types/AugmentationData";
import {Tooltip} from "@mui/material";

const ActivityComponent: React.FC<{
   style?: Object,
   activity?: ActivityDto,
   onlyTitle?: boolean,
   isNew?: string,
   classNames?: string,
   onClick?: () => void,
   canDelete?: boolean,
   isDeleted?: string,
   deleteActivity?: () => void,
   showFeedback?: boolean,
}> = props => {

   return (
      <>
         <animated.div style={props.style || {}} className={`custom-light-activity ${props.classNames || ""}`} onClick={onClickComponent}>
            {
               props.activity &&
               <>
                  <span>{props.activity.text}</span>
                  {
                     !props.onlyTitle &&
                     <div className="buttons-wrapper">
                        {
                           !props.showFeedback && props.activity.numberFeedbacks &&
                           <Tooltip title={`This activity has been marked as helpful with ${props.activity.numberFeedbacks} tool${props.activity.numberFeedbacks > 1 ? "s" : ""} at this time.`}>
                     <span className="feedback-value number-feedbacks">
                        <span className={"num"}>{props.activity.numberFeedbacks}</span>
                        <span className="star">⭐</span>
                     </span>
                           </Tooltip>
                        }
                        {
                           props.activity.feedback?.feedback === ToolFeedbackUses.POSITIVE &&
                           <Tooltip title={`This activity has previously been paired with this tool.`}>
                        <span className="feedback-value">
                           <span>⭐</span>
                        </span>
                           </Tooltip>
                        }
                        {
                           props.canDelete &&
                           <i className={`fa-solid ${props.isDeleted ? "fa-circle-check" : "fa-circle-xmark"}`}
                              onClick={onDeleteActivity}></i>
                        }
                     </div>
                  }
               </>
            }
            {
               props.isNew &&
               <>
                  <div className="title">
                     <span>{props.isNew}</span>
                     <span className={'new-tag'}>new</span>
                  </div>
                  {
                     props.canDelete &&
                     <i className={`fa-solid ${props.isDeleted ? "fa-circle-check" : "fa-circle-xmark"}`}
                        onClick={onDeleteActivity}></i>
                  }
               </>
            }
         </animated.div>
      </>
   );

   function onClickComponent(e: MouseEvent<HTMLElement>) {
      e.stopPropagation();
      props.onClick && props.onClick();
   }

   function onDeleteActivity(e: MouseEvent<HTMLElement>) {
      e.stopPropagation();
      props.deleteActivity && props.deleteActivity();
   }

}

export default ActivityComponent;