import React, {useContext, useEffect, useRef, useState} from "react";
import style from './FeedbackComponent.module.scss';
import {animated} from "react-spring";
import {createTrail, createTransition} from "../../../../../../utils/springStyles";
import {useNavigate, useSearchParams} from "react-router-dom";
import {showNotification} from "../../../../../../ui/Toast/ToastNotification";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {
   ActivityToolPairResponseDto,
   PastPairedActivitiesPerToolResponseDto, ToolFeedbackUses
} from "../../../../../../types/AugmentationData";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";
import useLoadingSpinner from "../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import AuthContext from "../../../../../../store/auth-context";
import useApiService from "../../../../../../services/api.service";
import {usePostHog} from "posthog-js/react";
import {capitalizeFirstLetter} from "../../../../../../utils/capitalize-first-letter";
import ActivityComponent from "../../AdditionalComponents/ActivityComponent";

const FeedbackComponent: React.FC<{}> = props => {

   const [pastActivities, setPastActivities] = useState<PastPairedActivitiesPerToolResponseDto>();
   const [activityInput, setActivityInput] = useState('');
   const activityInputRef = useRef<HTMLInputElement>(null);
   const [addedActivities, setAddedActivities] = useState<string[]>([]);

   const [searchParams] = useSearchParams();
   const toolId = searchParams.get("toolId");
   const activityId = searchParams.get("activityId");

   const fadeInComponent = createTransition(pastActivities, 250);
   const trailActivities = createTrail<string>(addedActivities);
   const trailUseCaseActivities = createTrail(pastActivities?.activities || []);

   const navigate = useNavigate();
   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();
   const posthog = usePostHog();

   useEffect(() => {
      if (!toolId) {
         showNotification("warning", "There was some error!", "No tool id present");
         navigate("/augmentation/augmentation-report");
      }
      initializeComponent(authStore.userData.accessToken);
      !activityId && saveToolFeedback(ToolFeedbackUses.ALREADY_USING, authStore.userData.accessToken);
   }, []);

   return (
      <>
         <div className={style['feedback-component']}>
            {
               fadeInComponent((style, data) => (
                  data && (
                     <animated.main style={style} className="main-section">
                        <h1 className="header-1">Thank you!</h1>

                        <div className="form-section">
                           <h2 className="header-2">Did you learn anything {pastActivities && pastActivities.activities?.length > 0 ? "else" : ""} <span className="text-primary-20">{data.tool.tool.name}</span> could do for you?</h2>

                           <div className="activity-input-container">
                              <input type="text" className={'custom-input'}
                                     ref={activityInputRef}
                                     placeholder={"Add another activity: Creating a cold email campaign"}
                                     onKeyDown={activityInputHandler} value={activityInput}
                                     onChange={e => setActivityInput(e.target.value)}/>
                              <button className="button button-primary"
                                      onClick={() => activityInputHandler(undefined, true)}
                                      disabled={activityInput.trim().length < 2}>Add activity
                              </button>
                           </div>

                           <div className={`activities-containers ${addedActivities.length > 0 && (pastActivities?.activities || []).length > 0 ? "added-gap" : ""}`}>
                              <div className="activities-container">
                                 {
                                    trailUseCaseActivities.map((style, index) => {
                                       const activity = pastActivities!.activities[index];
                                       return <ActivityComponent style={style} key={activity.id} activity={activity} classNames={"no-events"} onlyTitle={true} />
                                    })
                                 }
                              </div>
                              <div className={`activities-container`}>
                                 {
                                    trailActivities.map((style, index) => {
                                       const activityText = addedActivities[index];
                                       return <ActivityComponent key={index + activityText.slice(0, 2)} style={style} isNew={activityText} canDelete={true} deleteActivity={() => deleteAddedActivity(index)} />
                                    })
                                 }
                              </div>
                           </div>
                           <button className="button button-primary" onClick={() => saveFeedback(authStore.userData.accessToken)}>{addedActivities.length > 0 ? "Save and" : ""} Back to results</button>
                        </div>
                     </animated.main>
                  )
               ))
            }
         </div>
         {
            spinnerService.spinner
         }
      </>
   );

   function initializeComponent(accessToken: string) {
      const pastActivitiesURL = process.env.REACT_APP_PUBLIC_URL + `/augmentation/augmentation-report/past-activities-tool`;

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         toolId: Number(toolId)
      }

      spinnerService.createSpinner();

      axios
         .post(pastActivitiesURL, data, {headers})
         .then((response$: AxiosResponse<PastPairedActivitiesPerToolResponseDto>) => {
            posthog.capture("user_feedback_tool_component", {toolName: response$.data.tool.tool.name, fromPage: activityId ? "from_pair_activity_tool" : "from_tools_or_ai-readiness_page"});
            setPastActivities(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

   function saveToolFeedback(feedback: ToolFeedbackUses, accessToken: string) {
      const saveToolFeedbackURL = process.env.REACT_APP_PUBLIC_URL + `/augmentation/augmentation-report/save-tool-feedback`;

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      const data = {
         toolId: Number(toolId),
         feedback
      }

      axios
         .post(saveToolFeedbackURL, data, {headers})
         .then((_response$: AxiosResponse<boolean>) => {})
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     saveToolFeedback(feedback, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

   function saveFeedback(accessToken: string) {
      const saveFeedbackURL = process.env.REACT_APP_PUBLIC_URL + `/augmentation/augmentation-report/tool-use-cases`;

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      let useCasesText = '';

      if (addedActivities.length === 0 && !activityId) {
         posthog.capture("no_additional_activities_for_tool_feedback", {toolName: pastActivities!.tool.tool.name, fromPage: activityId ? "from_pair_activity_tool" : "from_tools_or_ai-readiness_page"});
         navigate(`/augmentation/augmentation-report`);
         return; // no added new activities
      }

      addedActivities.forEach((a, index) => (
         index === 0 ? useCasesText += a : useCasesText += `\n${a}`
      ));

      if (activityId && !isNaN(Number(activityId))) {
         const currentActivity = pastActivities?.activities.find(a => a.id === Number(activityId));
         if (currentActivity) {
            useCasesText += addedActivities.length > 0 ? `\n${currentActivity.text}` : currentActivity.text;
         }
      }

      const data = {
         toolId: Number(toolId),
         useCasesText,
         activityId: activityId ? Number(activityId) : undefined
      };

      spinnerService.createSpinner();

      axios
         .post(saveFeedbackURL, data, {headers})
         .then((_response$: AxiosResponse<ActivityToolPairResponseDto>) => {
            const posthogName = addedActivities.length === 0 ? "no_additional_activities_for_tool_feedback" : "added_additional_activities_for_tool_feedback";
            posthog.capture(posthogName, {toolName: pastActivities!.tool.tool.name, fromPage: activityId ? "from_pair_activity_tool" : "from_tools_or_ai-readiness_page"});
            navigate(`/augmentation/augmentation-report`);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     saveFeedback(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

   function activityInputHandler(e?: React.KeyboardEvent<HTMLInputElement>, fromButton?: boolean) {
      if (activityInput.trim().length > 1 && ((e && e.key === 'Enter') || fromButton)) {
         const fromAdded = addedActivities.find(a => a.toLowerCase().trim() === activityInput.toLowerCase().trim());
         const fromExisting = pastActivities!.activities.find(a => a.text.toLowerCase().trim() === activityInput.toLowerCase().trim());
         if (fromAdded || fromExisting) {
            showNotification("warning", "Please enter new activity.", "", activityInput.toLowerCase().trim());
         }
         else {
            setAddedActivities(prevState => [...prevState, capitalizeFirstLetter(activityInput.trim())]);
            setActivityInput('');
            fromButton && activityInputRef.current?.focus();
         }
      }
   }

   function deleteAddedActivity(index: number) {
      setAddedActivities(prevState => prevState.filter((_a, i) => index !== i));
   }
}

export default FeedbackComponent;