import React, {useEffect} from "react";
import PairPartnersComponent from "./PairPartnersComponent/PairPartnersComponent";
import ConfirmPartnersComponent from "./ConfirmPartnersComponent/ConfirmPartnersComponent";
import FeedbackComponent from "./FeedbackComponent/FeedbackComponent";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

const LearningPairComponent: React.FC<{}> = props => {

   const {section} = useParams<{section?: string}>();
   const [searchParams] = useSearchParams();
   const toolId = searchParams.get("toolId");
   const activityId = searchParams.get("activityId");
   const navigate = useNavigate();
   const sections = ['pair', 'confirm', 'feedback'];

   useEffect(() => {
      if (!sections.find(s => s === String(section))) {
         navigate("/augmentation/augmentation-report");
      }
      else if (!Number(toolId) && !Number(activityId)) {
         navigate("/augmentation/augmentation-report");
      }
   }, []);

   return (
      <>
         {
            section === 'pair' &&
            <PairPartnersComponent />
         }
         {
            section === 'confirm' &&
            <ConfirmPartnersComponent />
         }
         {
            section === 'feedback' &&
            <FeedbackComponent />
         }
      </>
   );
}

export default LearningPairComponent;