import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AuthContext from "./store/auth-context";

import LoginPage from "./components/LoginPage/LoginPage";
import MainPage from './components/MainPage/MainPage';
import ResetPasswordPage from './components/ResetPasswordPage/ResetPasswordPage';
import RegisterEmailPage from "./components/RegisterEmailPage/RegisterEmailPage";

import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/tabulator-tables/dist/css/tabulator.css';
import RegisterPage from "./components/RegisterPage/RegisterPage";
import LoadingComponent from "./components/MainPage/EmployeePages/AIImpactScorePage/LoadingComponent/LoadingComponent";

const App: React.FC = () => {
  const authStore = useContext(AuthContext);
  const userData = authStore.userData;
  const accessToken = userData ? userData.accessToken : null;
  const mainOrErrorPage = (userData && userData.menu && userData.menu.length > 0) ? <MainPage /> : <LoadingComponent style={{marginTop: '10vh'}} status={false} />;
  
  return (
    <React.Fragment>
      <Routes>
        <Route path='*' element={accessToken ? mainOrErrorPage : <Navigate replace to='/login'></Navigate>} />

        <Route path='/login' element={accessToken ? <Navigate replace to='/'></Navigate> : <LoginPage />} />

        <Route path='/register' element={accessToken ? <Navigate replace to='/'></Navigate> : <RegisterPage />} />

        <Route path='/register-email' element={accessToken ? <Navigate replace to='/'></Navigate> : <RegisterEmailPage />} />

        <Route path='/reset-password' element={accessToken ? <Navigate replace to='/'></Navigate> : <ResetPasswordPage />} />

        <Route path='*' element={<Navigate replace to='/'></Navigate>} />
      </Routes>
    </React.Fragment>
  );
}

export default App;