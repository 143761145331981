import React, {MouseEvent, useState} from "react";
import {animated} from "react-spring";
import {ToolFeedbackUses, TopAiToolsDto} from "../../../../../types/AugmentationData";
import {usePostHog} from "posthog-js/react";
import {useNavigate} from "react-router-dom";
import {createTransitionWithExit} from "../../../../../utils/springStyles";
import ModalComponent from "../../../../../ui/ModalComponent/ModalComponent";
import {Tooltip} from "@mui/material";

const ToolCardComponent: React.FC<{
   style: Object,
   tool: TopAiToolsDto,
   eventNames?: {p1: string, p2: string},
   onlyTitle?: boolean, classNames?: string,
   onClick?: (toolId: number) => void
}
> = ({style, tool, eventNames, onlyTitle, classNames, onClick}) => {

   const [showModal, setShowModal] = useState(false);
   const navigate = useNavigate();
   const posthog = usePostHog();
   const transitionModal = createTransitionWithExit(showModal, 250);
   const hasPositiveFeedback = tool.tool.feedback?.feedback && tool.tool.feedback.feedback === ToolFeedbackUses.POSITIVE;
   const hasNumFeedbacks = !hasPositiveFeedback && tool.tool.numberFeedbacks != null && tool.tool.numberFeedbacks > 0 && eventNames;

   return (
      <>
         <animated.div style={style || {}} className={`custom-dark-tool-card ${classNames || ""} ${onlyTitle ? "only-title" : ""}`} onClick={() => onClick && onClick(tool.tool.toolId)}>
            <div className="custom-dark-tool-card-header">
               <div className="header-wrapper">
                  <h2 className="header-2">{tool.tool.name}</h2>
                  {
                     tool.websiteUrl
                        ? <i className={`fa-solid fa-up-right-from-square`} onClick={openPage}></i>
                        : <Tooltip title={'There is currently no available link for the tool at this time.'}><i className="fa-solid fa-link-slash disabled"></i></Tooltip>
                  }

               </div>
            </div>
            {
               !onlyTitle && (hasPositiveFeedback || hasNumFeedbacks) &&
               <div className="feedback-for-activity">
                  <span className={"feedback-text"}>You marked this {hasNumFeedbacks ? "" : "pair"} as helpful</span>
                  <span className={`feedback-value ${hasNumFeedbacks ? "number-feedbacks" : ""}`}>
                     {
                        hasNumFeedbacks &&
                        <span className={"num"}>{hasNumFeedbacks ? tool.tool.numberFeedbacks : ""}</span>
                     }
                     <span className="star">⭐</span>
                  </span>
               </div>
            }
            {
               !onlyTitle &&
               <>
                  <p className="text-surface-40 tool-card-description">{tool.tool.description.slice(0, 400)}
                     {
                        tool.tool.description.length > 401 &&
                        <>
                           <span>...</span>
                           <button className="button button-tertiary" onClick={readDescription}>Show more</button>
                        </>
                     }
                  </p>
                  {
                     eventNames &&
                     <div className="buttons-wrapper">
                        <div className="button-wrapper">
                           <button className="button button-tertiary"
                                   onClick={() => navigateToPage(`/augmentation/augmentation-report/pair-component/pair?toolId=${tool.tool.toolId}`, eventNames.p1, {
                                      toolName: tool.tool.name,
                                      pair: true
                                   })}>I’d like to try this! 🚀
                           </button>
                        </div>
                        <div className="button-wrapper">
                           <button className="button button-tertiary"
                                   onClick={() => navigateToPage(`/augmentation/augmentation-report/pair-component/feedback?toolId=${tool.tool.toolId}`, eventNames.p2, {
                                      toolName: tool.tool.name,
                                      alreadyTried: true
                                   })}>I’ve already used this 🤷‍♂️
                           </button>
                        </div>
                     </div>
                  }
               </>
            }
         </animated.div>
         {
            transitionModal((style, showModal) => (
               showModal && (
                  <ModalComponent style={style}>
                     <h2 className="header-2 u-margin-bottom-xs">{tool.tool.name}</h2>
                     <p className="text-surface-30 u-margin-bottom-s">{tool.tool.description}</p>
                     <button className="button button-secondary" onClick={() => setShowModal(false)}>Close</button>
                  </ModalComponent>
               )
            ))
         }
      </>
   );

   function navigateToPage(url: string, posthogEventName: string, posthogData?: Object) {
      posthog.capture(posthogEventName, posthogData);
      navigate(url);
   }

   function openPage(e: MouseEvent<HTMLElement>) {
      e.stopPropagation();
      tool.websiteUrl && window.open(tool.websiteUrl, '_blank');
   }

   function readDescription(e: MouseEvent<HTMLElement>) {
      e.stopPropagation();
      setShowModal(true);
   }
}

export default ToolCardComponent;