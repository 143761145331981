import {useSpring, useTrail, useTransition} from "react-spring";

export const createFadeStyles = (duration: number, delay?: number) => {
   return useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration},
      delay
   });
}

export const createTransition = <T>(active: T, duration: number, delay?: number) => useTransition(active, {
   from: {opacity: 0},
   enter: {opacity: 1},
   config: {duration},
   delay
});

export const createTransitionWithExit = (active: boolean, duration: number, delay?: number) => useTransition(active, {
   from: {opacity: 0},
   enter: {opacity: 1},
   leave: {opacity: 0},
   config: {duration},
   delay
});

export const createTrail = <T>(array: T[]) => useTrail(array.length, {
   from: { opacity: 0 },
   to: { opacity: 1 },
   delay: 250,
   config: { tension: 150, friction: 40 }
});