import {SynonymDto} from "./Settings";
import {LevelDto} from "./Projects";

export type AugmentationReportDataRepresentationDto = {
   likeableAugmentedSkills: AugmentationSkillsRepresentationDto[];
   likeableNonAugmentedSkills: AugmentationSkillsRepresentationDto[];
   disLikeableAugmentedSkills: AugmentationSkillsRepresentationDto[];
   disLikeableNonAugmentedSkills: AugmentationSkillsRepresentationDto[];
   augmentedSkillsPercent: number;
   likeableAugmentedSkillsPercent: number;
   disLikeableAugmentedSkillsPercent: number;
   nonAugmentedSkillsPercent: number;
   likeableNonAugmentedSkillsPercent: number;
   disLikeableNonAugmentedSkillsPercent: number;
   aiImpactScore: number;
   sessionStatus: QuestionnaireSessionStatusEnum;
   augmentationNotification?: boolean;
   workshopVisibility: boolean;
}

export type AugmentationSkillsRepresentationDto = {
   skill: SkillDto;
   tools?: ToolDto[];
   counterTools?: number;
   isDeleted?: boolean;
}

export type SkillDto = {
   id: number;
   name: string;
   description: string;
   icon: string;
   category: string;
   aiId: string;
   isTool: boolean;
   isAITool: boolean;
   isGeneralSoftSkill: boolean;
   level: LevelDto;
   synonym?: SynonymDto;
   status?: number;
}

export type ToolInformation = {
   description: string;
   websiteUrl: string | null;
   hotSauce: HotSauceDto | null;
   feedback: ToolFeedbackDto | null;
}

export type HotSauceDto = {
   toolPros: string | null;
   toolCons: string | null;
   toolPricingCategory: string | null;
   toolPricingDescription: string | null;
   toolRating: string | null;
}

export type ToolFeedbackDto = {
   feedback: ToolFeedbackUses;
   startUsingDate?: Date;
}

export enum QuestionnaireSessionStatusEnum {
   OPEN = 'OPEN',
   PROCESSING = 'PROCESSING',
   FINISHED = 'FINISHED',
   FAILED = 'FAILED',
   BE_FAILED = 'BE_FAILED',
   UPDATED_REPORT_PROCESSING = "UPDATED_REPORT_PROCESSING"
}

export enum ToolFeedbackUses {
   START_USING = 'START_USING',
   IS_NOT_FOR_ME = 'IS_NOT_FOR_ME',
   ALREADY_USING = 'ALREADY_USING',
   POSITIVE = 'POSITIVE',
   NEGATIVE = 'NEGATIVE',
}

// FOR NEW AUGMENTATION REPORT
export type GeneralToolsRepresentationDto = {
   sessionStatus: QuestionnaireSessionStatusEnum;
   workshopVisibility: boolean;
   numberOfSkills?: number;
   numberOfAugSkills?: number;
   topGeneralTools?: GeneralToolsDto[];
   topSpecificTools?: ToolInformationDto[];
}

export type GeneralToolsDto = {
   toolId: number;
   toolName: string;
   focus: string;
   impact: string;
   estimatedUsers: string;
   feedback?: ToolFeedbackDto;
   website?: string;
}

export type ToolInformationDto = {
   id: number;
   name: string;
   description: string;
   websiteUrl: string | null;
   hotSauce: HotSauceDto | null;
   feedback: ToolFeedbackDto | null;
}

// FOR WORKSHOP
export type ExtractedSkillsDto = {
   skillId: number;
   skillName: string;
   totalUsers: number;
   totalWorkshopUsers: number;
   isDeleted?: boolean;
}

export type SkillMatchDto = {
   skillId: number;
   skillName: string;
   tools: ToolDto[];
   neighbours: string[];
}

export type ToolCoverageDto = {
   toolId: number;
   toolName: string;
   linkedSkills: string[];
   missingSkills: string[];
}

// NEWEST AUGMENTATION DATA
export type AugmentationReportTopAiToolsDto = {
   sessionStatus: QuestionnaireSessionStatusEnum;
   activitiesList: ActivityDto[];
   toolsList: TopAiToolsDto[];
   totalActivities: number;
   counterActivities: number;
   activePair: ActivityToolPairResponseDto;
}

export type ActivitiesPaginationResponseDto = {
   sessionStatus: QuestionnaireSessionStatusEnum;
   activities: ActivityDto[];
   totalActivities: number;
   counterActivities: number;
   page: number;
   pageSize: number;
   totalCount: number;
   totalPages: number;
}

export type ActivityDto = {
   id: number;
   text: string;
   type: ActivityTypeEnum;
   feedback: ToolFeedbackDto | null;
   dateAdded: Date;
   numberFeedbacks: number | null;
}

export enum ActivityTypeEnum {
   ACTIVITY = 'ACTIVITY',
   USE_CASE = 'USE_CASE',
}

export type ToolsPaginationResponseDto = {
   tools: TopAiToolsDto[];
   sessionStatus: QuestionnaireSessionStatusEnum;
   page: number;
   pageSize: number;
   totalCount: number;
   totalPages: number;
}

export type TopAiToolsDto = {
   priority: number;
   tool: ToolDto;
   websiteUrl?: string;
}

export type ToolDto = {
   toolId: number;
   name: string;
   description: string;
   feedback: ToolFeedbackDto | null;
   numberFeedbacks: number | null;
   isTopTool?: boolean;
}

export type AugmentedToolsPerActivityResponseDto = {
   activity: ActivityDto;
   tools: TopAiToolsDto[];
   page: number;
   pageSize: number;
   totalCount: number;
   totalPages: number;
   activityToolPairResponse: ActivityToolPairResponseDto;
}

export type AugmentedActivitiesPerToolResponseDto = {
   tool: TopAiToolsDto;
   activities: ActivityDto[];
   page: number;
   pageSize: number;
   totalCount: number;
   totalPages: number;
   activityToolPairResponse: ActivityToolPairResponseDto;
}

export type ActivityToolPairResponseDto  = {
   active: boolean;
   activity?: ActivityDto;
   tool?: TopAiToolsDto;
}

export type PastPairedActivitiesPerToolResponseDto = {
   tool: TopAiToolsDto;
   activities: ActivityDto[];
}

export type LearningOpportunitiesResponseDto = {
   questionnaireStatus: QuestionnaireSessionStatusEnum;
   toolFeedbacks: ActivityToolFeedbackDto[];
   activePair: ActivityToolPairResponseDto;
   page: number;
   pageSize: number;
   totalCount: number;
   totalPages: number;
}

export type ActivityToolFeedbackDto = {
   activity: ActivityDto;
   tool: TopAiToolsDto;
   feedback: ToolFeedbackUses;
}
