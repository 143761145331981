import React, {useContext, useEffect, useState} from "react";
import style from './ConfirmPartnersComponent.module.scss';
import {useNavigate, useSearchParams} from "react-router-dom";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";
import {showNotification} from "../../../../../../ui/Toast/ToastNotification";
import useLoadingSpinner from "../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import AuthContext from "../../../../../../store/auth-context";
import useApiService from "../../../../../../services/api.service";
import {createTransition} from "../../../../../../utils/springStyles";
import {animated} from "react-spring";
import {ActivityToolPairResponseDto, ToolFeedbackUses} from "../../../../../../types/AugmentationData";
import {usePostHog} from "posthog-js/react";
import ToolCardComponent from "../../AdditionalComponents/ToolCardComponent";
import ActivityComponent from "../../AdditionalComponents/ActivityComponent";

const ConfirmPartnersComponent: React.FC<{}> = props => {

   const [toolAndActivity, setToolAndActivity] = useState<ActivityToolPairResponseDto>();
   const [searchParams] = useSearchParams();
   const toolId = searchParams.get("toolId");
   const activityId = searchParams.get("activityId");

   const spinnerService = useLoadingSpinner();
   const navigate = useNavigate();
   const authStore = useContext(AuthContext);
   const authStoreAccessToken = authStore.userData.accessToken;
   const {refreshToken} = useApiService();
   const posthog = usePostHog();

   const fadeInComponent = createTransition<ActivityToolPairResponseDto | undefined>(toolAndActivity, 250);

   useEffect(() => {
      initializeComponent(authStoreAccessToken);
   }, []);

   return (
      <>
         <div className={style['confirm-partners-component']}>
            {
               fadeInComponent((style, data) => (
                  data && (
                     <animated.main className="main-section" style={style}>
                        <h1 className="header-1">Try to do the activity “<span
                           className="text-primary-20">{data.activity!.text}</span>” with <span className="text-primary-20">{data.tool!.tool.name}</span></h1>
                        <div className="tool-activity-section">
                           <ActivityComponent activity={data.activity!} onlyTitle={true} classNames={"no-events"} />

                           <i className="fa-solid fa-plus"></i>

                           <ToolCardComponent style={{}} tool={data.tool!} onlyTitle={true} />
                        </div>

                        <div className="footer-section">
                           <h2 className="header-2">Let us know how it went!</h2>
                           <h3 className="header-3">We’ll remind you if you forget</h3>
                           <button className="button button-secondary"
                                   onClick={() => saveToolFeedback(ToolFeedbackUses.POSITIVE, authStoreAccessToken)}>This was really helpful!
                           </button>
                           <button className="button button-secondary"
                                   onClick={() => saveToolFeedback(ToolFeedbackUses.NEGATIVE, authStoreAccessToken)}>This was not helpful
                           </button>
                           <button className="button button-tertiary"
                                   onClick={() => abortPair(authStoreAccessToken)}>Oops, I didn’t mean to select these. Abort.
                           </button>
                        </div>
                     </animated.main>
                  )
               ))
            }
         </div>
         {
            spinnerService.spinner
         }
      </>
   );

   function initializeComponent(accessToken: string) {
      const getActivePairURL = process.env.REACT_APP_PUBLIC_URL + `/augmentation/augmentation-report/active-pair`;

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .get(getActivePairURL, {headers})
         .then((response$: AxiosResponse<ActivityToolPairResponseDto>) => {
            if (!response$.data.active) {
               navigate("/augmentation/augmentation-report");
               return;
            }
            setToolAndActivity(response$.data);
            posthog.capture("user_active-pair_component", {toolName: response$.data.tool!.tool.name, activityName: response$.data.activity!.text});
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
               navigate("/augmentation/augmentation-report");
            }
         })
   }

   function saveToolFeedback(feedback: ToolFeedbackUses, accessToken: string) {
      const saveToolFeedbackURL = process.env.REACT_APP_PUBLIC_URL + `/augmentation/augmentation-report/save-tool-feedback`;

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      let data;

      if (toolAndActivity && toolAndActivity.tool && toolAndActivity.activity) {
         data = {
            toolId: toolAndActivity.tool.tool.toolId,
            activityId: toolAndActivity.activity.id,
            feedback
         }
      }
      else {
         return;
      }


      axios
         .post(saveToolFeedbackURL, data, {headers})
         .then((_response$: AxiosResponse<boolean>) => {
            posthog.capture("user_feedback_for_tool_activity", {toolName: toolAndActivity.tool!.tool.name, activityName: toolAndActivity.activity!.text, feedback});
            navigate(`/augmentation/augmentation-report/pair-component/feedback?toolId=${toolAndActivity.tool!.tool.toolId}&activityId=${toolAndActivity.activity!.id}`);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     saveToolFeedback(feedback, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

   function abortPair(accessToken: string) {
      const abortPairURL = process.env.REACT_APP_PUBLIC_URL + `/augmentation/augmentation-report/abort-pair`;

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      let data;

      if (toolAndActivity && toolAndActivity.tool && toolAndActivity.activity) {
         data = {
            toolId: toolAndActivity.tool.tool.toolId,
            activityId: toolAndActivity.activity.id,
         }
      }
      else {
         return;
      }

      spinnerService.createSpinner();

      axios
         .post(abortPairURL, data, {headers})
         .then((_response$: AxiosResponse<boolean>) => {
            posthog.capture("user_abort_for_tool_activity", {toolName: toolAndActivity.tool!.tool.name, activityName: toolAndActivity.activity!.text, abort: true});
            window.history.back();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     abortPair(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               spinnerService.removeSpinner();
            }
         })
   }

}

export default ConfirmPartnersComponent;